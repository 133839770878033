<template>
  <form @submit.prevent="handleSubmit(token)">
    <div class="form-control mt-1 form-control-custom">
      <label for="password">Contraseña:</label>
      <div class="input-w-icon input-form">
        <div class="input-w-icon input-form">
          <input
            type="password"
            name="password"
            ref="inputPassword"
            v-model="userForm.newPassword"
            placeholder="Ingrese su contraseña"
            required
          />
          <i
            @click="changeType"
            :class="[!showEyesIcon ? 'fas fa-eye' : 'fas fa-eye-slash']"
          ></i>
        </div>
      </div>
    </div>

    <div class="form-control mt-1 form-control-custom validation">
      <RequestMessageForm
        :requirementFulfilled="requeriments.hasMinimum10Characters"
        requirementText="Debe contener minimo 10 caracteres"
      />
      <RequestMessageForm
        :requirementFulfilled="requeriments.hasLowercase"
        requirementText="Debe contener por lo menos 1 minúscula"
      />
      <RequestMessageForm
        :requirementFulfilled="requeriments.hasUppercase"
        requirementText="Debe contener por lo menos 1 mayúscula"
      />
    </div>
    <div class="form-control mt-1 form-control-custom">
      <label for="password">Confirma tu contraseña:</label>
      <div class="input-w-icon input-form">
        <div class="input-w-icon input-form">
          <input
            type="password"
            name="password"
            ref="inputPassword2"
            placeholder="Ingrese su contraseña"
            v-model="userForm.newPassword2"
            required
          />
          <i
            @click="changeType2"
            :class="[!showEyesIcon2 ? 'fas fa-eye' : 'fas fa-eye-slash']"
          ></i>
        </div>
      </div>
    </div>

    <LoadingComponent v-if="isLoading_ === true" />
    <ModalLayout :isShow="isShow">
        <ModalContainer
          v-if="!isLoading_"
          :close="showOrClose" 
          :typeModal="typeModal"
          :imageModal="messageForm.messageType === 'success' ? '/images/decorations/success.png' : '/images/decorations/sorry.png'"
          :title="messageForm.messageType === 'success' ? '¡Enhorabuena!' : 'Lo sentimos'" 
          :description="messageForm.message" 
          :type="messageForm.messageType"/>
    </ModalLayout>
    <!-- <MessageComponent
      v-if="!isLoading && messageForm.message"
      :message="messageForm.message"
      :messageType="messageForm.messageType"
      :classes="['text-center', 'mt-1', 'form-message']"
    /> -->
    

    <div class="form-actions">
      <button
        type="submit"
        class="btn btn-secondary"
        :disabled="
          !requeriments.hasLowercase ||
          !requeriments.hasUppercase ||
          !requeriments.hasMinimum10Characters"
      >
        Crear contraseña
      </button>
    </div>
  </form>
</template>
<script>
import { LoadingComponent, MessageComponent } from "@/components";
import { RequestMessageForm } from "@/modules/auth/components/form-parts";
import useShowPassword from "@/modules/auth/composables/ui/useShowPassword";
import useNewPassword from "@/modules/auth/composables/new-password/useNewPassword";
import useValidateToken from "@/modules/auth/composables/new-password/useValidateToken";
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
export default {
  components: { RequestMessageForm, LoadingComponent, MessageComponent ,ModalLayout,
      ModalContainer },
  props: {
    title: {
      type: String,
      default: "Crea una nueva contraseña",
    },
    token: {
      type: String,
    },
  },
  setup() {
    const { changeType, inputPassword, showEyesIcon } = useShowPassword();
    const {
      changeType: changeType2,
      inputPassword: inputPassword2,
      showEyesIcon: showEyesIcon2,
    } = useShowPassword();
    return {
      changeType,
      inputPassword,
      showEyesIcon,
      changeType2,
      inputPassword2,
      showEyesIcon2,
      ...useNewPassword(),
      ...useValidateToken(),

    };
  },
  mounted() {
    this.validateToken(this.token);

    //router.push({ name: 'login' });
  },
};
</script>
